<template>
  <div>
    <div align="center" v-if="loader">
      <div class="spinner-border text-secondary"></div>
    </div>
    <table v-else class="table table-bordered text-center table-sm table-hover">
      <tbody>
        <tr class="">
          <th>Status of Report Submission</th>
          <th colspan="4" v-for="(ay, index) in academicYears" :key="index">
            {{ ay }}
          </th>
        </tr>
        <tr class="">
          <td></td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
          <td>1st</td>
          <td>2nd</td>
          <td>3rd</td>
          <td>Sum</td>
        </tr>

        <tr v-for="(p, index) in programs" :key="index">
          <td align="left">{{ p.name }}</td>
          <td>
            <span v-if="p.data[0]"><i :class="status(p.data[0])"></i></span>
          </td>
          <td>
            <span v-if="p.data[1]"><i :class="status(p.data[1])"></i></span>
          </td>
          <td>
            <span v-if="p.data[2]"><i :class="status(p.data[2])"></i></span>
          </td>
          <td>
            <span v-if="p.data[3]"><i :class="status(p.data[3])"></i></span>
          </td>
          <td>
            <span v-if="p.data[4]"><i :class="status(p.data[4])"></i></span>
          </td>
          <td>
            <span v-if="p.data[5]"><i :class="status(p.data[5])"></i></span>
          </td>
          <td>
            <span v-if="p.data[6]"><i :class="status(p.data[6])"></i></span>
          </td>
          <td>
            <span v-if="p.data[7]"><i :class="status(p.data[7])"></i></span>
          </td>
          <td>
            <span v-if="p.data[8]"><i :class="status(p.data[8])"></i></span>
          </td>
          <td>
            <span v-if="p.data[9]"><i :class="status(p.data[9])"></i></span>
          </td>
          <td>
            <span v-if="p.data[10]"><i :class="status(p.data[10])"></i></span>
          </td>
          <td>
            <span v-if="p.data[11]"><i :class="status(p.data[11])"></i></span>
          </td>
          <td>
            <span v-if="p.data[12]"><i :class="status(p.data[12])"></i></span>
          </td>
          <td>
            <span v-if="p.data[13]"><i :class="status(p.data[13])"></i></span>
          </td>
          <td>
            <span v-if="p.data[14]"><i :class="status(p.data[14])"></i></span>
          </td>
          <td>
            <span v-if="p.data[15]"><i :class="status(p.data[15])"></i></span>
          </td>
          <td>
            <span v-if="p.data[16]"><i :class="status(p.data[16])"></i></span>
          </td>

          <td>
            <span v-if="p.data[17]"><i :class="status(p.data[17])"></i></span>
          </td>

          <td>
            <span v-if="p.data[18]"><i :class="status(p.data[18])"></i></span>
          </td>

          <td>
            <span v-if="p.data[19]"><i :class="status(p.data[19])"></i></span>
          </td>

          <td>
            <span v-if="p.data[20]"><i :class="status(p.data[20])"></i></span>
          </td>

          <td>
            <span v-if="p.data[21]"><i :class="status(p.data[21])"></i></span>
          </td>

          <td>
            <span v-if="p.data[22]"><i :class="status(p.data[22])"></i></span>
          </td>
          <td>
            <span v-if="p.data[23]"><i :class="status(p.data[23])"></i></span>
          </td>
          <td>
            <span v-if="p.data[24]"><i :class="status(p.data[24])"></i></span>
          </td>
          <td>
            <span v-if="p.data[25]"><i :class="status(p.data[25])"></i></span>
          </td>
          <td>
            <span v-if="p.data[26]"><i :class="status(p.data[26])"></i></span>
          </td>
          <td>
            <span v-if="p.data[27]"><i :class="status(p.data[27])"></i></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <p class="mb-0">Legend:</p>
      <span class="mb-0 mr-2 font-italic">
        <i class="bi bi-check-circle-fill icon-sm text-warning"> </i> EL w/o PR
      </span>
      <span class=" font-italic">
        <i class="bi bi-check-circle-fill icon-sm text-success"> </i> EL w/ PR
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportTracker',
  data() {
    return {
      loader: '',
      academicYears: [
        '2017-2018',
        '2018-2019',
        '2019-2020',
        '2020-2021',
        '2021-2022',
        '2022-2023',
        '2023-2024',
      ],
      semesters: ['1st', '2nd', '3rd', 'Sum'],
      programs: [],
    };
  },
  methods: {
    getReportSubmissions() {
      this.loader = true;

      axios
        .get('api/hei-report-submissions/' + this.$attrs.hei_data.code)
        .then((res) => {
          this.programs = res.data;
          this.loader = false;
        })
        .catch((err) => {});
    },

    status($status) {
      return $status == 2
        ? 'bi bi-check-circle-fill icon-sm text-success'
        : 'bi bi-check-circle-fill icon-sm text-warning';
    },
    drawReportTracker() {
      const number_of_columns = 28;
      for (let z = 0; z < number_of_columns; z++) {
        this.columns[z] = '';
      }
    },
  },
  mounted() {
    this.getReportSubmissions();
  },
};
</script>
